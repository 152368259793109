
import { Ref, defineComponent, ref, onMounted, DefineComponent, onBeforeMount } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampConstrainProportionDiv
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { campHandleEmptyFields } from '@/composables/DataValidation';
// import { ProductImageLoader, ProductImageRack } from './components';
import stdImgURL from "@/views/Products/assets/upload-img.png"
import CampModalCropImage, { ImgResultsType } from "@/components/CampModalCropImage/CampModalCropImage.vue";
import { CampCropImageLoader, CampCropImageRack, CampUploadImgURL } from '@/components/CampModalCropImage';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

interface ICategory {
  id: number | null,
  name: string
}

interface ICategoryResponse {
  data: {
    error: boolean,
    message: string,
    data: ICategory[]
    errorCode: string
  },
  status: number
}

interface IProductData {
  id_category: number | null,
  name: string,
  description: string,
  points_default: number,
  sku: string,
  base64imgData: string | null
}

interface IProductResponse {
  data: {
    error: boolean,
    message: string,
    data: IProductData
    errorCode: string
  },
  status: number
}

interface ICampModalCropImage {}

export default defineComponent({
  name: "ProductPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampConstrainProportionDiv,
    CampCropImageLoader,
    CampCropImageRack,
    CampModalCropImage: CampModalCropImage as unknown as DefineComponent<{}, {}, ICampModalCropImage>
  },
  setup() {
    // Variables
    const loaderStore = useLoaderStore()
    const { showTimeAlert } = useAlert()
    const initData: IProductData = {
      id_category: null,
      name: "",
      description: "",
      points_default: 1,
      sku: "",
      base64imgData: null
    }
    const productImgURL = ref(CampUploadImgURL)
    const productImgIsLoading = ref(true)
    const productImgFile = ref<File | null>(null)
    const croppedImgReady: Ref<string | null> = ref(null)
    const productFields = ref<IProductData>({...initData})
    const categoryField = ref<ICategory[] | null>(null)
    const isRequiredField = ref(false)
    const filterBaseV2Store = useFilterBaseV2Store()

    // Functions
    function handleImgFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        productImgFile.value = file
      }
      fileInput.value = "";
    }

    function handleFileRemoval() {
      productImgIsLoading.value = false
      productImgURL.value = CampUploadImgURL
    }

    function handleCroppedImgBase64(value: ImgResultsType) {
      productImgURL.value = value.croppedImgBase64
      croppedImgReady.value = value.croppedImgBase64
      // console.log("Cropped Img File: ", (value.croppedImgFile.size / 1024**2).toFixed(1) + "MB")
      // console.log("Thumbnail Img File: ", (value.thumbnailImgFile!.size / 1024**2).toFixed(1) + "MB")
    }

    async function getCategories() {
      loaderStore.open()
      try {
        const company = await filterBaseV2Store.getCompanyInSession()
        if(company === null || !company) {
          loaderStore.close()
          return showTimeAlert("É necessário ter uma companhia setada", 'error')
        }
        const result: ICategoryResponse = await axios.get(`/api/getCategory?id_company=${company.id}`)
        categoryField.value = result.data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    }

    async function onSubmitForm() {
      const { base64imgData, ...res } = { ...productFields.value }
      const postData: IProductData = { ...res, base64imgData: croppedImgReady.value }
      const { description, ...requiredFields } = res

      if(campHandleEmptyFields(requiredFields)) {
        return isRequiredField.value = true
      }

      loaderStore.open()
      try {
        const result: IProductResponse = await axios.post(
          `/api/postProduct`,
          postData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        showTimeAlert("Registro criado com sucesso!")
        productFields.value = { ...initData }
        return router.push("/produtos")
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    // Hooks and life cycle
    onMounted(() => getCategories())

    return {
      CampUploadImgURL,
      productImgURL,
      productImgIsLoading,
      productImgFile,
      handleImgFileChange,
      handleFileRemoval,
      handleCroppedImgBase64,
      productFields,
      categoryField,
      isRequiredField,
      onSubmitForm,
    }
  }
})
